import { template as template_90b0172d2df746c9ba9016d61369336d } from "@ember/template-compiler";
const WelcomeHeader = template_90b0172d2df746c9ba9016d61369336d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
